// Dependencies
import React, { FC } from "react";
import styled from "styled-components";

// Components
import Chevron from "./svg/chevron";

interface ArrowProps {
  next?: Boolean;
  onClick?: any;
  black: Boolean;
}

const arrow: FC<ArrowProps> = ({ black, next, onClick }) => {
  return (
    <StyledArrow right={next} onClick={onClick}>
      <Chevron right={next} black={black} />
    </StyledArrow>
  );
};

export default arrow;

const StyledArrow = styled.div<{ right: Boolean }>`
  display: none;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  position: absolute;
  top: 50%;
  left: ${props => (props.right ? "" : "0")};
  right: ${props => (props.right ? "0" : "")};
  z-index: 10;
  margin-right : 8px;
  margin-left : 10px;
  cursor: pointer;
  transform: translate(0, -50%);
  svg {
    max-height: 30px;
  }
  @media (min-width: ${props => props.theme.breakpoints[2]}) {
    display: flex;
    height: 44px;
    width: 44px;
  }
`;
