// Dependencies
import React, { Fragment } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal';
import { graphql } from 'gatsby';
import GraphImg from 'graphcms-image';

// Components
import SEO from '../components/seo';
import DoubleSlider from '../components/doubleSlider';
import { Container } from '../pages/index';
import List from '../components/list';
import { PageTitle } from '../pages/pakiety';

const About: React.FC<any> = ({ data }) => {
  const { strengths, slogan, pageTitle } = data.cms.aboutPages[0];
  const sections = data.cms.aboutSections;

  return (
    <>
      <Fade>
        <SEO />
        <Container>
          <PageTitle>{pageTitle}</PageTitle>
          <StyledArticle smallMargin>
            <HeadingNoUppercase>{slogan}</HeadingNoUppercase>
            <p>{strengths.title}</p>
            <List details={strengths.list} smallMargin isBold withGap />
            {strengths.doneList.map((item, index) => (
              <p>{item}</p>
            ))}
          </StyledArticle>
          {sections.map((section, index) => {
            return (
              <Fragment key={index}>
                {section.galleryImages.length > 0 ? (
                  <DoubleSlider images={section.galleryImages} />
                ) : null}
                <StyledArticle
                  smallMargin={
                    sections.length - 1 === index ||
                    sections.length - 2 === index
                  }
                >
                  <Heading>{section.content.heading}</Heading>
                  {section.content.content.map((c, index) => {
                    return <p key={index}>{c}</p>;
                  })}
                  {section.content.descriptionList ? (
                    <List details={section.descriptionList} withGap />
                  ) : null}
                  {section.content.descriptionLead ? (
                    <p>{section.content.descriptionLead}</p>
                  ) : null}
                  {section.content.secondDescriptionList ? (
                    <List
                      details={section.content.secondDescriptionList}
                      withGap
                    />
                  ) : null}
                </StyledArticle>
              </Fragment>
            );
          })}
        </Container>
      </Fade>
    </>
  );
};

export default About;

const HeadingNoUppercase = styled.h2`
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  margin: ${(props) => props.theme.space[6]}px 0
    ${(props) => props.theme.space[4]}px 0;
`;

const Heading = styled.h2`
  font-size: ${(props) => props.theme.fontSizes[5]}px;
  text-transform: uppercase;
  margin: ${(props) => props.theme.space[6]}px 0
    ${(props) => props.theme.space[4]}px 0;
`;

const StyledArticle = styled.article<{ smallMargin?: boolean }>`
  margin-bottom: ${(props) =>
    props.smallMargin ? props.theme.space[8] : props.theme.space[10]}px;

  p {
    margin-bottom: ${(props) => props.theme.space[3]}px;
  }
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          aboutPage {
            heading
            content
            descriptionLead
            descriptionList
            secondDescriptionList
            images {
              childImageSharp {
                fluid(quality: 85, maxWidth: 920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    cms {
      aboutPages {
        slogan
        strengths
        pageTitle
      }
      aboutSections {
        content
        galleryImages {
          handle
          width
          height
        }
      }
    }
  }
`;
