// Dependencies
import React, { Component } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import Img from 'gatsby-image';
import GraphImg from 'graphcms-image';

// Components
import Arrow from '../components/smallArrow';

export default class LightBox extends Component {
  state = {
    nav1: null,
    nav2: null,
  };

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const settings = {
      nextArrow: <Arrow black={true} next />,
      prevArrow: <Arrow black={true} />,
    };
    const settingsFirst = {
      responsive: [
        {
          breakpoint: 786,
          settings: {
            autoplay: true,
            autoplaySpeed: 2500,
          },
        },
      ],
    };
    const imageArray = this.props.images;

    return (
      <StyledLightBoxContainer className="doubleSlider">
        <SliderWrapper>
          <FirstSlider
            asNavFor={this.state.nav2}
            ref={(slider) => (this.slider1 = slider)}
            arrows={false}
            {...settingsFirst}
          >
            {imageArray.map((image, index) => {
              return (
                // <StyledImg key={index} fluid={image.childImageSharp.fluid} />
                <StyledImg
                  image={{
                    handle: image.handle,
                    width: 1840,
                    height: 1840 / (image.width / image.height),
                  }}
                  withWebp
                />
              );
            })}
          </FirstSlider>
        </SliderWrapper>
        <StyledThumbsWrapper>
          <Slider
            className="thumbSlider"
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={true}
            {...settings}
          >
            {imageArray.map((image, index) => {
              return (
                // <StyledThumb key={index} fluid={image.childImageSharp.fluid} />
                <StyledThumb
                  image={{
                    handle: image.handle,
                    width: 920,
                    height: 920 / (image.width / image.height),
                  }}
                  withWebp
                />
              );
            })}
          </Slider>
        </StyledThumbsWrapper>
      </StyledLightBoxContainer>
    );
  }
}

const StyledImg = styled(GraphImg)`
  height: 480px;
  border-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 350px;
  }
  @media screen and (max-width: 576px) {
    height: 250px;
  }
`;
const StyledThumb = styled(GraphImg)`
  display: flex;
  height: 200px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;

  .slick-current & {
    border: 2px solid ${(props) => props.theme.colors.white};
  }
`;
const StyledThumbsWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const StyledLightBoxContainer = styled.div`
  z-index: 23;
`;

const FirstSlider = styled(Slider)`
  height: calc(480px);
  margin-bottom: ${(props) => props.theme.space[4]}px;
  @media screen and (max-width: 768px) {
    height: 350px;
  }
  @media screen and (max-width: 576px) {
    height: 250px;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
`;
